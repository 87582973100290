<template>
  <div>
    <div class="flex flex-col font-Inter px-10">
      <Loading v-if="componentLoanding" />
      <div class="lg:flex lg:items-center lg:justify-between mb-3 py-4">
        <div class="mt-1 flex flex-col justify-center">
          <h2
            class="text-3xl font-medium leading-7 uppercase text-gray-900 sm:text-3xl sm:truncate"
          >
            Nouvelle facture d'achat
          </h2>
          <!-- <p class="text-gray-400 text-xs mt-2 w-1/2 text-justify">
            Pour enregistrer un devis le numero est générer par le système et
            veuillez renseigner les informations obligatoire (
            <strong class="text-red-500">*</strong>) et vous pouvez a tout
            moment convertir le devis en facture ou bon de commande
          </p> -->
        </div>
      </div>

      <div class="mb-20 overflow-y-auto shadow bg-white border p-6">
        <div class="flex flex-col mx-auto">
          <el-form
            :model="bill"
            :rules="rules"
            ref="billForm"
            :label-position="labelPosition"
          >
            <div class="flex justify-between items-start">
              <div class="flex flex-col w-1/2">
                <search-supplier
                  @supplier="handleSelectedSupplier"
                  @search-supplier="handleSearchSupplier"
                  :customerInfo="customerInfo"
                  :suppliers="suppliers"
                />
              </div>
              <div class="flex flex-col justify-end items-end w-1/2">
                <div
                  class="text-3xl font-medium text-gray-900 sm:text-3xl sm:truncatemt-2 my-2"
                >
                  Total : {{ totalTTC | moneyFilter }} {{user.currency_code}}
                </div>
                <div class="flex">
                  <el-form-item prop="bill_date" label="Date" class="mr-2">
                    <el-date-picker
                      v-model="bill.bill_date"
                      type="date"
                      placeholder="jjmmmmmaaaa"
                      format="dd MMMM yyyy"
                      value-format="yyyy-MM-dd"
                    >
                    </el-date-picker>
                  </el-form-item>
                  <el-form-item prop="bill_no" label="N° Facture">
                    <el-input v-model="bill.bill_no"> </el-input>
                  </el-form-item>
                </div>

                <div class="flex">
                  <el-form-item
                    label="Date d'écheance"
                    prop="due_date"
                    class="mr-2"
                  >
                    <el-date-picker
                      v-model="bill.due_date"
                      type="date"
                      placeholder="jj-mm-aaaa"
                      format="dd MMMM yyyy"
                      value-format="yyyy-MM-dd"
                    >
                    </el-date-picker>
                  </el-form-item>
                  <el-form-item label="Réference">
                    <el-input v-model="bill.reference"> </el-input>
                  </el-form-item>
                </div>
                <el-form-item label="Modalité de paiement" class="flex-1">
                  <el-input
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 2 }"
                    placeholder="Ecrire ici"
                    v-model="bill.terms"
                  />
                </el-form-item>
              </div>
            </div>
          </el-form>

          <div class="customer-white mt-5">
            <add-article
              :articles="articles"
              :items="items"
              :subTotal="totalHT"
              :totalTTC="totalTTC"
              :sale="false"
              @delete="handleDeleteItem"
              @search-article="handleSearchArticle"
              @add-item-article="handeAddArticle"
              @discount="updateDiscount"
              @percentDiscount="updatePercentDiscount"
              @taxe="updateTaxe"
              @shipping="updateShipping"
            />
          </div>
        </div>
      </div>
    </div>
    <page-footer>
      <div class="flex justify-between px-10 py-2">
        <div>
          <div
            class="text-2xl font-medium leading-7 text-gray-900 sm:text-2xl sm:truncatemt-2 my-2"
          >
            TOTAL: {{ totalTTC | moneyFilter }} {{user.currency_code}}
          </div>
        </div>

        <div>
          <el-button
            type="info"
            :disabled="!itemsNoEmpty"
            icon="el-icon-save"
            @click="createData('DRAFT')"
            plain
            >Enregistrer au brouillon</el-button
          >
          <el-button
            type="primary"
            icon="el-icon-save"
            :loading="loading"
            @click="createData('OPEND')"
            :disabled="!itemsNoEmpty"
            >Enregistrer et confirmer</el-button
          >
          <!-- 
          <el-dropdown
            class="mr-2"
            :disabled="!itemsNoEmpty"
            split-button
            type="primary"
            @command="handleCreate"
          >
            Finaliser
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="OPEND" class="text-md font-medium"
                >Enregistrer et confirmer
              </el-dropdown-item>
              <el-dropdown-item command="DRAFT" class="text-md font-medium"
                >Enregistrer au brouillon</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown> -->
        </div>
      </div>
    </page-footer>
  </div>
</template>

<script>
import { createBill } from "@/api/purchase";

import { parseTime, formatDate } from "@/utils";
import { toThousandFilter } from "@/Filters";
import Loading from "@/components/Loanding/Full";
import AddArticle from "@/components/AddArticle";
import SearchSupplier from "@/components/AddSupplier";
import PageFooter from "@/components/PageFooter";
import {mapGetters} from "vuex";
export default {
  name: "CreateBill",
  filters: {
    filterTime: parseTime,
    moneyFilter: toThousandFilter,
  },
  components: { Loading, AddArticle, SearchSupplier, PageFooter },
  data() {
    return {
      labelPosition: "top",
      componentLoanding: false,
      loading: false,
      articles: [],
      loadingCustomerInfo: false,
      suppliers: [],
      duedays: [],
      articleName: "",
      customerName: "",
      selectedArticle: "",
      dialogReceived: false,
      customerInfo: {
        contact_id: "",
        phone: "",
        email: "",
        first_name: "",
        last_name: "",
        street: "",
        contact_no: "",
        organization_name: "",
      },

      loadingArticle: false,
      loadingCustomer: false,
      term: "",
      items: [
        {
          article_id: "",
          article_no: "",
          article_name: "",
          price_purchase: 0,
          price_sale: 0,
          description: "",
          price: 0,
          percent_discount: true,
          discount: 0,
          percent_tax: 0,
          quantity: 0,
          total: 0,
          warehouse_code: "",
          warehouse_name: "",
          stock_onhand: "",
          stocks: [],
        },
        {
          article_id: "",
          article_no: "",
          article_name: "",
          price_purchase: 0,
          price_sale: 0,
          description: "",
          price: "",
          percent_discount: true,
          discount: 0,
          percent_tax: 0,
          quantity: 0,
          total: 0,
          warehouse_code: "",
          warehouse_name: "",
          stock_onhand: "",
          stocks: [],
        },
        {
          article_id: "",
          article_no: "",
          article_name: "",
          price_purchase: 0,
          price_sale: 0,
          description: "",
          price: "",
          percent_discount: true,
          discount: 0,
          percent_tax: 0,
          quantity: 0,
          total: 0,
          warehouse_code: "",
          warehouse_name: "",
          stock_onhand: "",
          stocks: [],
        },
        {
          article_id: "",
          article_no: "",
          article_name: "",
          price_purchase: 0,
          price_sale: 0,
          description: "",
          price: "",
          percent_discount: true,
          discount: 0,
          percent_tax: 0,
          quantity: 0,
          total: 0,
          warehouse_code: "",
          warehouse_name: "",
          stock_onhand: "",
          stocks: [],
        },
        {
          article_id: "",
          article_no: "",
          article_name: "",
          price_purchase: 0,
          price_sale: 0,
          description: "",
          price: "",
          percent_discount: true,
          discount: 0,
          percent_tax: 0,
          quantity: 0,
          total: 0,
          warehouse_code: "",
          warehouse_name: "",
          stock_onhand: "",
          stocks: [],
        },
      ],
      bill: {
        contact_id: "",
        bill_no: "",
        bill_date: formatDate(new Date()),
        due_date: "",
        note: "",
        status: "DRAFT",
        reference: "",
        percent_tax: 0,
        currency:"",
        discount: 0,
        received_product: false,
        terms: "",
        shipping_amount: 0,
        type: "BILL",
        items: [],
      },
      rules: {
        contact_id: [
          {
            required: true,
            message: "Fournisseur est obligatoire",
            trigger: ["change", "blur"],
          },
        ],

        bill_date: [
          {
            required: true,
            message: "La date est obligatoire",
            trigger: "change",
          },
        ],
        bill_no: [
          {
            required: true,
            message: "La numéro facture est obligatoire",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
      ...mapGetters(["user"]),
    totalHT: function () {
      var subtotal = 0;

      this.items.forEach((el) => {
        let totalHT = el.quantity * el.price;
        let discount = 0;
        let tax;
        if (el.percent_discount) {
          discount = (totalHT * el.discount) / 100;
        } else {
          discount = el.discount;
        }
        tax = ((totalHT - discount) * el.percent_tax) / 100;
        subtotal += totalHT - discount + tax;
      });

      return Math.floor(subtotal);
    },
    totalTTC: function () {
      var total = 0;
      total =
        this.totalHT +
        this.bill.shipping_amount -
        (this.totalHT * this.bill.discount) / 100 +
        (this.totalHT * this.bill.percent_tax) / 100;

      console.log(total);
      return Math.floor(total);
    },
    itemsNoEmpty: function () {
      return this.items.some((item) => item.article_id !== "");
    },
  },
  mounted() {},
  methods: {
    handleDelete(id) {
      const index = this.bill.items.findIndex((el) => el.articleId === id);
      this.bill.items.splice(index, 1);
    },

    createData(status) {
      this.$refs["billForm"].validate((valid) => {
        if (valid) {
          this.componentLoanding = true;
          this.bill.items = this.items.filter(
            (el) => el.article_id !== "" && el.article_id !== undefined
          );
          this.bill.status = status;
          this.bill.currency = this.user.currency_code
          createBill(this.bill)
            .then((res) => {
              setTimeout(() => {
                this.componentLoanding = false;
                this.$router.push(`/purchases/bills/${res.data._id}`);
              }, 1.5 * 1000);
            })
            .catch((err) => {
              this.componentLoanding = false;
              if (err.data.status === 400) {
                this.$message({
                  message:
                    "Veuillez renseigner les informations obligatoire et les articles",
                  type: "warning",
                });
              } else if (err.data.status === 409) {
                this.$message({
                  message: err.data.message,
                  type: "warning",
                });
              } else {
                this.$message({
                  message: "Erreur serveur",
                  type: "error",
                });
              }
            });
        }
      });
    },
    handleCreate(value) {
      this.createData(value);
    },
    handleSearchArticle(data) {
      this.articles = data;
    },
    handeAddArticle() {
      this.items.push({
        article_id: "",
        article_no: "",
        article_name: "",
        price_purchase: 0,
        price_sale: 0,
        description: "",
        price: "",
        percent_tax: 0,
        percent_discount: false,
        discount: 0,
        quantity: 0,
        warehouse_code: "",
        warehouse_name: "",
        stock_onhand: "",
        stocks: [],
      });
    },
    handleSelectedSupplier(id) {
      console.log(id);

      const supplier = this.suppliers.find((c) => c.contact_id === id);
      this.customerInfo = Object.assign({}, supplier);
      this.bill.contact_id = id;
    },
    handleSearchSupplier(data) {
      this.suppliers = data;
    },
    handleDeleteItem(id) {
      const index = this.items.findIndex((el) => el.article_id === id);
      this.items.splice(index, 1);
    },
    updateDiscount(e) {
      this.bill.discount = e;
    },
    updatePercentDiscount(e) {
      this.bill.percent_discount = e;
      this.bill.discount = 0;
    },
    updateTaxe(e) {
      this.bill.percent_tax = e;
    },
    updateShipping(e) {
      this.bill.shipping_amount = e;
    },
  },
};
</script>

<style></style>
